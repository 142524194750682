import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {AnimatePresence, motion} from "framer-motion";
import {Link, graphql, useStaticQuery} from "gatsby";
import {LocomotiveScrollProvider} from "react-locomotive-scroll";
import SEO from "../components/helper/SEO";
import PageTransition from "../components/layout/PageTransition";
import MyPortableText from "../components/helper/MyPortableText";
import Navigation from "../components/layout/Navigation";
import GoogleTag from "../components/analytics/GoogleTag";
import {UserConsentProvider} from "../util/UserConsentContext";

const Layout = ({children, location}) => {

    const ref = useRef(null);

    const options = {
        smooth: true,
    }


    const {allSanitySettings} = useStaticQuery(graphql`
        query siteSettings {
            allSanitySettings{
                edges {
                    node {
                        maintenance
                        maintenance_seo_description
                        maintenance_seo_title
                        maintenancetitle
                        maintenancebutton {
                            value
                            title
                        }
                        _rawMaintenancetext
                        newsletter {
                            button {
                                en
                                de
                                _type
                            }
                            _rawText
                        }
                    }
                }
            }
        }
    `)

    const [maintenanceMode, setMaintenanceMode] = useState(true);

    useEffect(() => {
        if (location?.hostname !== 'leadingexecutivepartners.com') {
            setMaintenanceMode(false);
        }
    }, [location]);

    const staticSettings = allSanitySettings.edges[0].node;

    console.log("##############");
    console.log(location);

    const [isLanding, setIsLanding] = useState(false);

    useEffect(() => {
        if (location.pathname === '/') {
            setIsLanding(true);
        } else {
            setIsLanding(false);
        }
    }, [location])

    if (staticSettings?.maintenance && maintenanceMode) {
        console.log('maintenance mode aktiv')
        return (<>
            <link rel="stylesheet" href="https://use.typekit.net/lfo6icp.css"/>

            <SEO title={staticSettings?.maintenance_seo_title} description={staticSettings?.maintenance_seo_description} location={location}></SEO>
            <div className={"maintenance top-0 left-0 h-screen w-screen bg-white flex justify-center items-center flex-col"} style={{
                height: '100vh',
                width: '100vw'
            }}>
                <div className="reveal">
                    <h2 className={`text-5xl tablet:text-7xl z-10 relative mb-5 center-aligned reveal`}>{staticSettings?.maintenancetitle}</h2>
                </div>
                <div className={"text-center reveal"}>
                    <MyPortableText value={staticSettings?._rawMaintenancetext}></MyPortableText>
                </div>
                <div className={"flex justify-center reveal"}>
                    <Link className="link tracking-wider uppercase text-xs mt-5 hover:-translate-y-0.5 duration-500 ease-in-out text-center inline-block" to={staticSettings?.maintenancebutton?.value}>{staticSettings?.maintenancebutton?.title}</Link>
                </div>
            </div>
        </>)
    } else {
        console.log('maintenance mode inaktiv')
        return (<><UserConsentProvider><GoogleTag/>
            <main>
                <link rel="stylesheet" href="https://use.typekit.net/lfo6icp.css"/>
                <PageTransition location={location}>
                    {children}
                </PageTransition>
            </main>
        </UserConsentProvider>
        </>)
    }

}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout

import React, {useEffect, useRef, useState} from "react";
import MyPortableText from "../helper/MyPortableText";
import {Link} from "gatsby";
import addToMailchimp from "gatsby-plugin-mailchimp";


const NewsletterForm = ({isDark}) => {

    let [newsletterText, setNewsletterText] = useState('Newsletter abonnieren');
    let [newsletterSuccess, setNewsletterSuccess] = useState(false);
    let [newsletterLoading, setNewsletterLoading] = useState(false);
    let [newsletterError, setNewsletterError] = useState(false);
    let [newsletterMailChimpError, setNewsletterMailChimpError] = useState(false);

    let newsletterMessageRef = useRef();

    function handleChange(e) {
        value[e.target.id] = e.target.value;
        setNewsletterSuccess(false);
        setNewsletterLoading(false);
        setNewsletterError(false);
        setNewsletterMailChimpError(false);
        setValue({...value})
    }

    const [value, setValue] = React.useState({})

    let emailRef = useRef();

    function restartNewsletter(){
        setNewsletterSuccess(false);
        setNewsletterLoading(false);
        setNewsletterError(false);
        setNewsletterMailChimpError(false);
        setValue({});
    }

    async function onSubmit(e) {
        e.preventDefault()

        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (value.emailnewsletter && value.emailnewsletter.match(mailformat)) {
            setNewsletterLoading(true)
            addToMailchimp(value.emailnewsletter)
                .then(data => {
                    setNewsletterText('E-Mail erfolgreich hinzugefügt!');
                    setNewsletterSuccess(true)
                })
                .catch(() => {
                    setNewsletterText('Ein Fehler ist aufgetreten.');
                    setNewsletterMailChimpError(true);
                    setNewsletterSuccess(true);
                })
        }
        else{
            setNewsletterError(true);
        }
    }

    return (<div className={`newsletter`}>
            <div className={`newsletter-inner`}>
                <form onSubmit={onSubmit} id={"form"} className="newsletter-input">
                    <label for={"emailnewsletter"} className={`newsletter-input-wrapper ${newsletterError ? 'error' : ''} ${isDark ? 'dark' : ''}`}>
                        <div className="newsletter-input-wrapper-inner">
                            <input type="email" ref={emailRef} autoComplete={"off"} placeholder={"Hier eintragen für den Newsletter"} className={`inputfield ${isDark ? 'dark' : ''} ${newsletterSuccess ? 'active' : ''}`} name={"EMAIL"} id={"emailnewsletter"} value={value[`emailnewsletter`] || ``} onChange={handleChange}></input>
                            <div className={`newsletter-message ${newsletterSuccess ? 'active' : ''}`}>{newsletterText} {newsletterMailChimpError ? <span onClick={restartNewsletter} className="underlined hover-moveup" style={{display: 'inline-block'}}>Erneut versuchen.</span> : <></>}</div>
                        </div>
                    </label>
                    <button type={"submit"} className={`btn ${isDark ? 'btn-filled-light' : 'btn-filled'} btn-newsletter ${newsletterLoading ? 'btn-newsletter-success' : ''}`} onClick={() => {
                    }}>
                        <span className="btn-text-wrapper">
                            <p className={`lftetica`}>Abonnieren</p>
                        </span>
                    </button>
                </form>
                <h6 className={"plantin"}>Wir verarbeiten Ihre Daten gemäß
                    unserer <Link to={"/privacy"} className={`${isDark ? 'color-light' : 'color-dark'}  underlined hover-moveup`} style={{display: 'inline-block'}}>Datenschutzrichtlinie</Link>.
                </h6>
            </div>
        </div>)
}


export default NewsletterForm


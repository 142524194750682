import React, {useEffect, useRef, useState} from "react";
import MyPortableText from "../helper/MyPortableText";
import {graphql, Link, useStaticQuery} from "gatsby";
import {useUserConsent} from "../../util/UserConsentContext";
import Cookies from "js-cookie";

const DatenschutzAcceptance = ({location, cookie}) => {

    const [showBanner, setShowBanner] = useState(false);

    const { consent, setConsent } = useUserConsent();
    const [ localConsent, setLocalConsent ] = useState(consent);

    const marketingConsent = useRef();

    useEffect(() => {
        const savedConsent = Cookies.get('leadingexecutivepartners-cookie-consent');
        if (savedConsent) {
            const consentObject = JSON.parse(savedConsent);
            setLocalConsent(consentObject);
        } else {
            setShowBanner(true);
        }
    }, []);

    const handleConsent = () => {
        Cookies.set('leadingexecutivepartners-cookie-consent', JSON.stringify(localConsent), { expires: 365 });
        setShowBanner(false);
        setConsent(localConsent);
        if (!localConsent.marketing) {
            window.location.reload();
        }
    };
    const handleConsentAll = () => {
        console.log(localConsent);
        Cookies.set('leadingexecutivepartners-cookie-consent', JSON.stringify({technical: true, marketing: true}), { expires: 365 });
        setShowBanner(false);
        setConsent({...consent, marketing: true});
        setLocalConsent({...localConsent, marketing: true});
        console.log(localConsent);
    };


    if (!showBanner) return null;

    return (
        <div className={`datenschutzacceptance`}>
            <div className={`datenschutzacceptance-inner`}>
                <h4 className={"lftetica uppercase datenschutzacceptance-title"}>{cookie?.title}</h4>
                <MyPortableText value={cookie?._rawText}></MyPortableText>
                <label className="datenschutzacceptance-cb">
                    <span className="lftetica">
                        Technisch
                    </span>
                    <input type="checkbox" value={"option"} checked disabled/>
                    <span className="checkmark"></span>
                </label>
                <label className="datenschutzacceptance-cb">
                    <span className="lftetica">
                        Marketing
                    </span>
                    <input type="checkbox" id={"marketing"} ref={marketingConsent} checked={localConsent.marketing} onChange={() => setLocalConsent({...localConsent, marketing: !localConsent.marketing})}/>
                    <span className="checkmark"></span>
                </label>
                <div className="datenschutzacceptance-btns btn-container">
                    <div className={`btn acceptance-btn btn-outline`} onClick={handleConsent}>{cookie?.button_secondary}</div>
                    <div className={`btn acceptance-btn btn-filled`} onClick={handleConsentAll}>{cookie?.button_primary}</div>
                </div>
            </div>
        </div>
    )
}


export default DatenschutzAcceptance


import { PortableText } from '@portabletext/react';
import React from 'react';
import {getImage, getImageDimensions} from "@sanity/asset-utils";
import {getSanityImageData} from "../../util/getSanityImageData";
import {GatsbyImage} from "gatsby-plugin-image";
import sanityConfig from '../../../sanity-config'

const textComponents = {
    block: {
        normal: ({ children }) => <p>{children}</p>,
        h1: ({ children }) => <h1>{children}</h1>,
        h2: ({ children }) => <h2>{children}</h2>,
        h3: ({ children }) => <h3 className={"plantin italic portableh3"}>{children}</h3>,
        h4: ({ children }) => <h4>{children}</h4>,
        h5: ({ children }) => <h5>{children}</h5>,
        h6: ({ children }) => <h6>{children}</h6>,
    },
    marks: {
        strong: ({children}) => <span className={"strong"}>{children}</span>,
        link: ({value, children}) => {
            const target = (value?.href || '').startsWith('http') ? '_blank' : undefined
            return (
                <a href={value?.href} target={target} rel={target === '_blank' && 'noindex nofollow'} className="underlined">
                    {children}
                </a>
            )
        },
    },
    types: {
        imageAlt: ({value}) => {
            const imageData = getImage(value.asset, sanityConfig).asset;
            const {width, height} = getImageDimensions(value);

            const image = {
                url: imageData.url,
                width,
                height,
            };

            const gatsbyImageData = getSanityImageData({
                image,
                layout: 'constrained',
            });

            if(value.link){
                return (
                    <a href={value.link} target={"_blank"}>
                        <div className={`imageAlt blockcontent-image-container ${value.credits ? "withCredits" : ""}`}>
                            <GatsbyImage className={`page-image blockcontent-image`} image={gatsbyImageData} alt={value.alt}></GatsbyImage>
                            {value.credits ?
                                <h6 className={"lftetica"}>Credits: {value.credits}</h6>
                                :
                                <></>}
                        </div>
                    </a>
                );
            }
            else {
                return (
                    <div className={`imageAlt blockcontent-image-container ${value.credits ? "withCredits" : ""}`}>
                        <GatsbyImage className={`page-image blockcontent-image`} image={gatsbyImageData} alt={value.alt}></GatsbyImage>
                        {value.credits ?
                            <h6 className={"lftetica"}>{value.credits}</h6>
                            :
                            <></>}
                    </div>
                );
            }
        },
    }
};

function MyPortableText({ value }) {
    return <PortableText value={value} components={textComponents}/>;
}

export default MyPortableText;

import { Script } from "gatsby";
import React from "react";
import { useUserConsent } from "../../util/UserConsentContext";

const GoogleTag = () => {
    const { consent } = useUserConsent();

    return (
        <>{consent.marketing && (
            <>
                <Script src="https://www.googletagmanager.com/gtag/js?id=AW-11334448901"></Script>
                <Script id="ads" dangerouslySetInnerHTML={{
                    __html: `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'AW-11334448901');
                    `}}
                />
                <Script src="https://www.googletagmanager.com/gtag/js?id=G-36XQ9CJB8"></Script>
                <Script dangerouslySetInnerHTML={{
                    __html: `
                        window.dataLayer = window.dataLayer || []; 
                        function gtag(){dataLayer.push(arguments);} 
                        gtag('js', new Date()); 
                        gtag('config', 'G-36XQ9CJB8');
                    `}}
                />
                <Script dangerouslySetInnerHTML={{
                    __html: `
                        window.gtag_report_conversion = function(url) {
                            var callback = function () {
                                if (typeof(url) != 'undefined') {
                                    window.location = url;
                                }
                            };
                            gtag('event', 'conversion', {
                                'send_to': 'AW-11334448901/p9XUCOnR7-AYEIXu15wq',
                                'event_callback': callback
                            });
                            return false;
                        };
                    `}}
                />
            </>
        )}</>
    );
};

export default GoogleTag;

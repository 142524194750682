import React, {useEffect, useRef, useState} from "react";
import Icon from "../helper/Icon";
import {Link} from "gatsby";
import styled from "styled-components";
import {navigate} from 'gatsby';
import DatenschutzAcceptance from "./DatenschutzAcceptance";
import MyPortableText from "../helper/MyPortableText";
import NewsletterForm from "./NewsletterForm";
import {useLocation} from '@reach/router';

import {useLocomotiveScroll} from "react-locomotive-scroll";

const Menu = styled.div`
  display: ${({nav}) => (nav ? "flex" : "none")} !important;
`

const Newsletter = styled.div`
  display: ${({newsletter}) => (newsletter ? "block" : "none")} !important;
`

const NavSocialMediaLink = styled.div`
  display: ${({nav}) => (nav ? "flex" : "none")} !important;
`

const Navigation = ({location, settings, isLanding, navDark, showHeadline, headline}) => {
    const [nav, changeNavState] = useState(false);

    const [newsletterActive, changeNewsletterActive] = useState(true);
    const [newsletter, changeNewsletterState] = useState(true);

    const menuRef = useRef();
    const logoRef = useRef();
    const poweredbyRef = useRef();
    const seperatorRef = useRef();

    const changeNav = () => {
        if (nav) {
            document.body.classList.remove("stop-scrolling");
            document.getElementById('home').classList.remove('reveal');
            document.getElementById('home').classList.add('hide-block');

            document.getElementById('about').classList.remove('reveal');
            document.getElementById('about').classList.add('hide-block');

            document.getElementById('coaches').classList.remove('reveal');
            document.getElementById('coaches').classList.add('hide-block');

            document.getElementById('coaches2').classList.remove('reveal');
            document.getElementById('coaches2').classList.add('hide-block');

            document.getElementById('coaching').classList.remove('reveal');
            document.getElementById('coaching').classList.add('hide-block');

            document.getElementById('coaching2').classList.remove('reveal');
            document.getElementById('coaching2').classList.add('hide-block');

            document.getElementById('gehaltsstudien').classList.remove('reveal');
            document.getElementById('gehaltsstudien').classList.add('hide-block');

            document.getElementById('kontakt').classList.remove('reveal');
            document.getElementById('kontakt').classList.add('hide-block');

            document.getElementById('blog').classList.remove('reveal');
            document.getElementById('blog').classList.add('hide-block');


            if (document.getElementById('linkedin').classList.contains('reveal-alt')) {
                document.getElementById('linkedin').classList.remove('reveal-alt');
            }
            document.getElementById('linkedin').classList.add('hide-alt');

            document.getElementById('xing').classList.remove('reveal-alt');
            document.getElementById('xing').classList.add('hide-alt');


            document.getElementById('datenschutz').classList.remove('reveal');
            document.getElementById('datenschutz').classList.add('hide-block');

            document.getElementById('impressum').classList.remove('reveal');
            document.getElementById('impressum').classList.add('hide-block');

            setTimeout(() => {
                document.getElementById('home').classList.remove('hide-block');
                document.getElementById('about').classList.remove('hide-block');
                document.getElementById('coaches').classList.remove('hide-block');
                document.getElementById('coaches2').classList.remove('hide-block');
                document.getElementById('coaching').classList.remove('hide-block');
                document.getElementById('coaching2').classList.remove('hide-block');
                document.getElementById('gehaltsstudien').classList.remove('hide-block');
                document.getElementById('kontakt').classList.remove('hide-block');
                document.getElementById('blog').classList.remove('hide-block');
                document.getElementById('impressum').classList.remove('hide-block');
                document.getElementById('datenschutz').classList.remove('hide-block');
                console.log(document.getElementById('home').classList)
                changeNavState(false);

                if (document.getElementById('linkedin').classList.contains('hide-alt')) {
                    document.getElementById('linkedin').classList.remove('hide-alt');
                }

                if (document.getElementById('xing').classList.contains('hide-alt')) {
                    document.getElementById('xing').classList.remove('hide-alt');
                }

                if (isLanding && window.scrollY < 100) {
                    if (window.innerWidth > 768) {
                        logoRef.current.style.transform = "scale(5)";
                    } else {
                        logoRef.current.style.transform = "scale(2.5)";
                    }
                    seperatorRef.current.style.transition = 'transform .3s ease-in-out'
                    seperatorRef.current.style.transform = "scaleX(0)";
                }
            }, 400)
        } else {
            console.log(document.getElementById('home').classList)

            document.body.classList.add("stop-scrolling");
            changeNavState(true);
            if (!document.getElementById('home').classList.contains('reveal')) {
                document.getElementById('home').classList.add('reveal');
            }

            if (!document.getElementById('about').classList.contains('reveal')) {
                document.getElementById('about').classList.add('reveal');
            }

            if (!document.getElementById('coaches').classList.contains('reveal')) {
                document.getElementById('coaches').classList.add('reveal');
            }

            if (!document.getElementById('coaches2').classList.contains('reveal')) {
                document.getElementById('coaches2').classList.add('reveal');
            }

            if (!document.getElementById('coaching').classList.contains('reveal')) {
                document.getElementById('coaching').classList.add('reveal');
            }

            if (!document.getElementById('coaching2').classList.contains('reveal')) {
                document.getElementById('coaching2').classList.add('reveal');
            }

            if (!document.getElementById('gehaltsstudien').classList.contains('reveal')) {
                document.getElementById('gehaltsstudien').classList.add('reveal');
            }

            if (!document.getElementById('kontakt').classList.contains('reveal')) {
                document.getElementById('kontakt').classList.add('reveal');
            }

            if (!document.getElementById('blog').classList.contains('reveal')) {
                document.getElementById('blog').classList.add('reveal');
            }


            if (!document.getElementById('linkedin').classList.contains('reveal-alt')) {
                document.getElementById('linkedin').classList.add('reveal-alt');
            }

            if (!document.getElementById('xing').classList.contains('reveal-alt')) {
                document.getElementById('xing').classList.add('reveal-alt');
            }

            if (!document.getElementById('datenschutz').classList.contains('reveal')) {
                document.getElementById('datenschutz').classList.add('reveal');
            }

            if (!document.getElementById('impressum').classList.contains('reveal')) {
                document.getElementById('impressum').classList.add('reveal');
            }


            if (isLanding) {

                logoRef.current.style.transform = "scale(1)";
                seperatorRef.current.style.transition = 'transform .7s ease-in-out'
                seperatorRef.current.style.transform = "scaleX(1)";
            }
        }
    }

    const newsletterRef = useRef();

    useEffect(() => {
        const handleScroll = () => {
            if (isLanding && !nav) {
                let currentScrollPos = window.scrollY;
                if (currentScrollPos > 10) {
                    if (poweredbyRef.current.classList.contains('reveal-text')) {
                        poweredbyRef.current.classList.remove('reveal-text');
                    }
                    if (poweredbyRef.current.classList.contains('reveal-text2')) {
                        poweredbyRef.current.classList.remove('reveal-text2');
                    }
                    poweredbyRef.current.classList.add('hide-text');
                } else {
                    if (poweredbyRef.current.classList.contains('hide-text')) {
                        poweredbyRef.current.classList.remove('hide-text');
                        poweredbyRef.current.classList.add('reveal-text');
                    }

                }

                if (currentScrollPos > 100) {
                    logoRef.current.style.transform = "scale(1)";

                    seperatorRef.current.style.transition = 'transform .7s ease-in-out'
                    seperatorRef.current.style.transform = "scaleX(1)";
                    newsletterRef.current.style.opacity = "0";
                    setTimeout(() => {
                        changeNewsletterState(false)
                    }, 300)
                } else {
                    newsletterRef.current.style.opacity = "1";
                    changeNewsletterState(true)
                    setTimeout(() => {
                        changeNewsletterState(true)
                    }, 300)

                    if (window.innerWidth > 768) {
                        logoRef.current.style.transform = "scale(5)";
                    } else {
                        logoRef.current.style.transform = "scale(2.5)";
                    }
                    seperatorRef.current.style.transition = 'transform .3s ease-in-out'
                    seperatorRef.current.style.transform = "scaleX(0)";
                }
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    return (<div>
        <nav className={`navigation ${nav ? 'expanded' : ''} ${navDark ? 'navigation-dark' : ''}`}>
            <div className="nav-inner">
                <div className={`nav-left`}>
                    <div className={`nav-logo ${isLanding ? 'landing-logo' : ''}`} ref={logoRef}>
                        <Link to={"/"}>
                            <div className="logo">
                                <div className={"reveal-text leading"}>
                                    <span>LEADING</span>
                                </div>
                                <div className={"reveal-text executive"} data-delay={"200ms"}>
                                    <span>EXECUTIVE</span>
                                </div>
                                <div className={"reveal-text partners"} data-delay={"400ms"}>
                                    <span>PARTNERS</span>
                                </div>
                            </div>
                        </Link>
                        {isLanding ? <div className={`poweredby reveal-text2`} ref={poweredbyRef}>
                            <span>
                                <span className="plantin-light">Powered by </span> <span className={"strong"}><a href="https://leadingsearchpartners.com/" target={"_blank"} className={"hover-underlinealt"}>LEADING SEARCH PARTNERS</a></span>
                            </span>
                        </div> : <></>}

                    </div>
                </div>
                <div className={`nav-center`}>
                    <h4 className={`uppercase plantin-light italic ${showHeadline ? "visible" : ""}`}>{headline}</h4>
                </div>
                <div className={`nav-right`}>
                    <div className={"reveal-text"} data-delay={"200ms"}>

                        <p className={"lftetica uppercase hover-underline burger"} onClick={() => {
                            changeNav();
                        }}>
                            <span className={`burger-text ${nav ? 'burger-text-close' : ''}`}><span>CLOSE</span></span>
                        </p>
                    </div>
                </div>
            </div>
            <Menu className={`menu`} nav={nav} ref={menuRef}>
                <div className="menu__link__container">
                    <Link className={"menu-link"} to={"/"} onClick={() => {
                        changeNav()
                    }}>
                        <h2 id={"home"} className={"plantin-light italic uppercase reveal nav-reveal"} data-delay={"1s"}>
                            <span>HOME</span></h2>
                    </Link>
                    <Link className={"menu-link"} to={"/about"} onClick={() => {
                        changeNav()
                    }}>
                        <h2 id={"about"} className={"plantin-light italic uppercase reveal nav-reveal"} data-delay={"1s"}>
                            <span>ABOUT</span>
                        </h2>
                    </Link>
                    <div className="coachescoaching-wrapper desktop">
                        <Link className={"menu-link"} to={"/coaches"} onClick={() => {
                            changeNav()
                        }}>
                            <h2 id={"coaches"} className={"plantin-light italic uppercase reveal nav-reveal"} data-delay={"1s"}>
                                <span>COACHES</span></h2>
                        </Link>
                        <Link className={"menu-link"} to={"/coaching"} onClick={() => {
                            changeNav()
                        }}>
                            <h2 id={"coaching"} className={"plantin-light italic uppercase reveal nav-reveal"} data-delay={"1s"}>
                                <span>COACHING</span></h2>
                        </Link>
                    </div>
                    <Link className={"menu-link mobile"} to={"/coaches"} onClick={() => {
                        changeNav()
                    }}>
                        <h2 id={"coaches2"} className={"plantin-light italic uppercase  reveal nav-reveal"} data-delay={"1s"}>
                            <span>COACHES</span></h2>
                    </Link>
                    <Link className={"menu-link mobile"} to={"/coaching"} onClick={() => {
                        changeNav()
                    }}>
                        <h2 id={"coaching2"} className={"plantin-light italic uppercase  reveal nav-reveal"} data-delay={"1s"}>
                            <span>COACHING</span></h2>
                    </Link>
                    <Link className={"menu-link"} to={"/gehaltsstudien"} onClick={() => {
                        changeNav()
                    }}>
                        <h2 id={"gehaltsstudien"} className={"plantin-light italic uppercase  reveal nav-reveal"} data-delay={"1s"}>
                            <span>GEHALTSSTUDIEN</span></h2>
                    </Link>
                    <Link className={"menu-link"} to={"/contact"} onClick={() => {
                        changeNav()
                    }}>
                        <h2 id={"kontakt"} className={"plantin-light italic uppercase  reveal nav-reveal"} data-delay={"1s"}>
                            <span>KONTAKT</span></h2>
                    </Link>
                    <Link className={"menu-link"} to={"/blog"} onClick={() => {
                        changeNav()
                    }}>
                        <h2 id={"blog"} className={"plantin-light italic uppercase reveal nav-reveal"} data-delay={"1s"}>
                            <span>BLOG</span></h2>
                    </Link>
                </div>
                <div className={"menu-sublinks"}>
                    <Link to={"/imprint"} className={"hover-moveup"}>
                        <h4 id={'impressum'} className={"lftetica uppercase reveal"} data-delay={"1s"}>
                            <span>IMPRESSUM</span></h4></Link>
                    <Link to={"/privacy"} className={"hover-moveup"}>
                        <h4 id={'datenschutz'} className={"lftetica uppercase reveal"} data-delay={"1s"}>
                            <span>Datenschutz</span></h4></Link>
                </div>

            </Menu>
            <NavSocialMediaLink className="nav-linkedin" nav={nav}>
                <a id={"linkedin"} href={"linkedin.com"} target="_blank" className="btn btn-circle btn-nav" data-delay={"1s"}>
                    LINKEDIN
                </a>
            </NavSocialMediaLink>
            <NavSocialMediaLink className="nav-xing" nav={nav}>
                <a id={"xing"} href={"linkedin.com"} target="_blank" className="btn btn-circle btn-nav" data-delay={"1.1s"}>
                    XING
                </a>
            </NavSocialMediaLink>
            <div className={`nav-separator ${isLanding ? 'nav-landing-separator' : ''}`} ref={seperatorRef}></div>
        </nav>
        {isLanding ? <Newsletter newsletter={newsletter} className="index-newsletter-wrapper" ref={newsletterRef}>
            <div className={`index-newsletter reveal ${newsletterActive ? 'active' : ''}`} data-delay={"3s"}>
                <div className="newsletter-text">
                    <h4 className={"lftetica uppercase"}>Executive Blog</h4>
                    <MyPortableText value={settings.newsletter._rawText}></MyPortableText>
                </div>
                <NewsletterForm></NewsletterForm>
            </div>
        </Newsletter> : <></>}
    </div>)
}
export default Navigation;

/*


 */
